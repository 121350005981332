(function () {
  'use strict';
  angular
    .module('module.translations')
    .factory('TranslationService', [
      '$q',
      '$http',
      'SettingsService',
      'ApiUrlService',
      'bugsnagClient',
      service,
    ]);
  function service($q, $http, SettingsService, ApiUrlService, bugsnagClient) {
    let translations = null;

    function logError(message) {
      bugsnagClient.notify(message);
    }

    function loadTranslations() {
      const deferred = $q.defer();
      const url = ApiUrlService.getApiUrl('/translations');
      if (translations) {
        deferred.resolve(translations);
      } else {
        $http
          .get(url)
          .then(function (response) {
            translations = response.data;
            validateTranslations(translations);
            deferred.resolve(translations);
          })
          .catch(function (error) {
            const errorMessage = 'Error loading translations: ' + (error.data || error.statusText);
            logError(errorMessage);
            deferred.reject(errorMessage);
          });
      }
      return deferred.promise;
    }

    function getLocale() {
      return SettingsService.getSettings().then(function (settings) {
        return settings.metadata.locale;
      });
    }

    function translateKey(key) {
      return loadTranslations().then(function () {
        return getLocale().then(function (locale) {
          if (!key) {
            return key;
          }

          const lookupKey = key.toLowerCase();
          if (translations[lookupKey] && translations[lookupKey][locale]) {
            return translations[lookupKey][locale];
          } else {
            logError(`Missing translation for key: "${key}" in locale: "${locale}"`);
            return key;
          }
        });
      });
    }

    function translateMultiple(keys) {
      const translationPromises = keys.map(translateKey);
      return $q.all(translationPromises);
    }

    function translateTerms(terms, scope) {
      return translateMultiple(terms)
        .then((translations) => {
          scope.translations = {};
          terms.forEach((term, index) => {
            scope.translations[term] =
              translations[index] !== undefined ? translations[index] : term;
          });
        })
        .catch((error) => {
          logError('Translation error: ' + error);
          terms.forEach((term) => {
            scope.translations[term] = term;
          });
        });
    }

    function validateTranslations(translations) {
      const locales = ['UK', 'US'];
      Object.keys(translations).forEach((key) => {
        const translation = translations[key];
        locales.forEach((locale) => {
          if (!translation[locale]) {
            logError(`Missing locale "${locale}" for key "${key}"`);
          }
        });
      });
    }

    return {
      translate: function (key) {
        return translateKey(key);
      },
      translateMultiple: translateMultiple,
      translateTerms: translateTerms,
    };
  }
})();
